import React, { useEffect, useMemo, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { getSelectorsByUserAgent } from 'react-device-detect';
import { css } from '@emotion/react';

import PageLayout from '../components/PageLayout';
import { ContentMain, RichTextView } from '../components/commonStyled';
import LoadingBlocker from '../components/LoadingBlocker';
import Sections from '../components/Sections';

const TextContainer = styled.div`
    padding: 24px 0;
`;

const CanvasWrapper = styled.div<{ isMobile: boolean }>`
    display: block;
    position: relative;
    width: 100%;

    ${({ isMobile }) =>
        isMobile
            ? css`
                  height: 90vh;
              `
            : css`
                  padding-top: 56.25%;
              `}
`;

const CanvasStyled = styled.canvas<{ isMobile: boolean }>`
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
`;
const ASSET_NAME = 'ProfCalc';

const withAppAssetsUrl = (asset: string, isMobile: boolean) =>
    !isMobile
        ? `/${ASSET_NAME}/Build/${asset}`
        : `/${ASSET_NAME}Mobile/Build/${asset}`;

const getCanvasData = (isMobile: boolean) => {
    return {
        dataUrl: withAppAssetsUrl(`${ASSET_NAME}.data`, isMobile),
        frameworkUrl: withAppAssetsUrl(`${ASSET_NAME}.framework.js`, isMobile),
        codeUrl: withAppAssetsUrl(`${ASSET_NAME}.wasm`, isMobile),
    };
};

const loadInitialScript = async (isMobile: boolean) => {
    return new Promise((resolve, reject) => {
        try {
            const script = document.createElement('script');
            script.src = withAppAssetsUrl(`${ASSET_NAME}.loader.js`, isMobile);

            const scriptHeader = document.getElementsByTagName('script')[0];
            scriptHeader.parentNode.insertBefore(script, scriptHeader);

            script.onload = () => {
                resolve(true);
            };
        } catch (e) {
            reject(e);
        }
    });
};

export default ({ data, location }) => {
    const {
        title,
        seo,
        content,
        common_acf: { blocks },
    } = data?.wpPage;

    const [userAgent, setUserAgent] = useState<string>();

    const { isMobile } = getSelectorsByUserAgent(userAgent) || {
        isMobile: null,
    };

    const [isLoaded, setIsLoaded] = useState(true);

    const { bgUrl, codeUrl, frameworkUrl, dataUrl } = useMemo(
        () => getCanvasData(isMobile),
        [isMobile]
    );

    const canvasRef = useRef();

    useEffect(() => {
        const agent = window?.navigator?.userAgent;
        if (agent) setUserAgent(agent);

        const initUnity = async () => {
            const isLoaded = await loadInitialScript(isMobile);

            if (isLoaded) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                createUnityInstance(canvasRef.current, {
                    dataUrl,
                    frameworkUrl,
                    codeUrl,
                    streamingAssetsUrl: 'StreamingAssets',
                    companyName: 'Sisyphean Labor',
                    productName: `${ASSET_NAME}`,
                    productVersion: '1.0',
                });

                setIsLoaded(false);
            }
        };

        if (isMobile !== null) initUnity();
    }, [codeUrl, dataUrl, frameworkUrl, isMobile]);

    return (
        <PageLayout
            breadcrumbs={[
                {
                    url: location?.pathname,
                    title,
                },
            ]}
            title={title}
            seoMeta={seo}
        >
            <ContentMain>
                <CanvasWrapper isMobile={isMobile}>
                    <CanvasStyled
                        ref={canvasRef}
                        isMobile={isMobile}
                        style={{
                            background: `url('${bgUrl}') center / cover`,
                        }}
                    />
                    <LoadingBlocker isLoading={isLoaded} />
                </CanvasWrapper>
                <TextContainer>
                    <RichTextView
                        fontSize={18}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </TextContainer>
            </ContentMain>
            <Sections enabledSections={blocks || []} />
        </PageLayout>
    );
};

export const pageQuery = graphql`
    query ProfCalc {
        wpPage(common_acf: { id: { eq: "prof-calc" } }) {
            title
            content
            seo {
                ...Seo
            }
            common_acf {
                blocks
            }
        }
    }
`;
